import React, { useEffect } from "react";
import Aos from "aos";

import Coupons from "./Coupons";
import Announcements from "./Announcements";
import Comments from "./Comments";
import Infographic from "./Infographic";


const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  return (
    <>
      <div className="container" data-aos="fade-up">
 
        <Coupons />
        <h1 className="py-2" style={{ fontFamily: "'Pacifico', cursive" }}>
          Bienvenido a TataraBueno
        </h1>
        <Announcements />
        <hr className="mt-5" />
        <Infographic />
        <hr className="my-5" />
        <Comments />
      </div>
      
    </>
  );
};

export default Home;
