// import React, { useState, useEffect } from "react";

// import Skeleton from "@yisheng90/react-loading";

// import { connect } from "react-redux";
// import { compose } from "redux";
// import { firestoreConnect } from "react-redux-firebase";

// import Notification from "../../notification/Notification";
// import ProductList from "../../products/ProductList";

// const Menu = ({ products, profile, auth, history, filtered }) => {
//   const [filteredChoripan, setFilteredChoripan] = useState([]);
//   const [filteredEntrante, setFilteredEntrante] = useState([]);
//   const [filteredSalchipapa, setFilteredSalchipapa] = useState([]);
//   const [filteredHamburguesa, setFilteredHamburguesa] = useState([]);
//   const [filteredPostre, setFilteredPostre] = useState([]);
//   const [filteredPerrito, setFilteredPerrito] = useState([]);
//   const [filteredExtra, setFilteredExtra] = useState([]);
//   const [filteredBebida, setFilteredBebida] = useState([]);
//   const [filteredSalsa, setFilteredSalsa] = useState([]);
//   const [filteredBatido, setFilteredBatido] = useState([]);

//   const [filteredProducts, setFilteredProducts] = useState([]);
//   useEffect(() => {
//     setFilteredProducts(filtered);
//   }, [filtered]);

//   useEffect(() => {
//     setFilteredEntrante(
//       products && products.filter((item) => item.category === "entrante")
//     );
//   }, [filtered, filteredEntrante, products]);

//   useEffect(() => {
//     setFilteredSalchipapa(
//       products && products.filter((item) => item.category === "salchipapa")
//     );
//   }, [filtered, filteredSalchipapa, products]);

//   useEffect(() => {
//     setFilteredHamburguesa(
//       products && products.filter((item) => item.category === "hamburguesa")
//     );
//   }, [filtered, filteredHamburguesa, products]);

//   useEffect(() => {
//     setFilteredChoripan(
//       products && products.filter((item) => item.category === "choripan")
//     );
//   }, [filtered, filteredChoripan, products]);

//   useEffect(() => {
//     setFilteredPerrito(
//       products && products.filter((item) => item.category === "perrito")
//     );
//   }, [filtered, filteredPerrito, products]);

//   useEffect(() => {
//     setFilteredExtra(
//       products && products.filter((item) => item.category === "extra")
//     );
//   }, [filtered, filteredExtra, products]);

//   useEffect(() => {
//     setFilteredBebida(
//       products && products.filter((item) => item.category === "bebida")
//     );
//   }, [filtered, filteredBebida, products]);

//   useEffect(() => {
//     setFilteredBatido(
//       products && products.filter((item) => item.category === "batido")
//     );
//   }, [filtered, filteredBatido, products]);

//   useEffect(() => {
//     setFilteredPostre(
//       products && products.filter((item) => item.category === "postre")
//     );
//   }, [filtered, filteredPostre, products]);

//   useEffect(() => {
//     setFilteredSalsa(
//       products && products.filter((item) => item.category === "salsa")
//     );
//   }, [filtered, filteredSalsa, products]);

//   // useEffect(() => {
//   //   const entrantes =
//   //     products && products.filter((item) => item.category === "entrante");
//   //   setFilteredEntrante(entrantes);
//   //   const salchipapa =
//   //     products && products.filter((item) => item.category === "salchipapa");
//   //   setFilteredSalchipapa(salchipapa);
//   //   const hamburguesa =
//   //     products && products.filter((item) => item.category === "hamburguesa");
//   //   setFilteredHamburguesa(hamburguesa);
//   //   const choripan =
//   //     products && products.filter((item) => item.category === "choripan");
//   //   setFilteredChoripan(choripan);

//   //   const perrito =
//   //     products && products.filter((item) => item.category === "perrito");
//   //   setFilteredPerrito(perrito);
//   //   const extra =
//   //     products && products.filter((item) => item.category === "extra");
//   //   setFilteredExtra(extra);
//   //   const bebida =
//   //     products && products.filter((item) => item.category === "bebida");
//   //   setFilteredBebida(bebida);
//   //   const batido =
//   //     products && products.filter((item) => item.category === "batido");
//   //   setFilteredBatido(batido);
//   //   const salsa =
//   //     products && products.filter((item) => item.category === "salsa");
//   //   setFilteredSalsa(salsa);

//   //   const postre =
//   //     products && products.filter((item) => item.category === "postre");
//   //   setFilteredPostre(postre);
//   // }, [filtered]);

//   // const entrante = products && products.filter((item) => item.category === "entrante");
//   // const postres = products && products.filter((item) => item.category === "postre");
//   // const salchipapas = products && products.filter((item) => item.category === "salchipapa");
//   // const hamburguesas = products && products.filter(
//   //   (item) => item.category === "hamburguesa"
//   // );
//   // const extras = products &&  products.filter((item) => item.category === "extra");
//   // const choripan = products && products.filter((item) => item.category === "choripan");
//   // const perrito = products && products.filter((item) => item.category === "perrito");
//   // const salsas = products && products.filter((item) => item.category === "salsa");
//   // const bebidas = products && products.filter((item) => item.category === "bebida");
//   // const batidos = products && products.filter((item) => item.category === "batido");

//   // console.log("produsct=====", filteredProducts);
//   return (
//     <>
//       <div className="container pt-2">
//         <h3> Entrantes: </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredEntrante && (
//             <ProductList
//               products={filteredEntrante}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Las salchipapas (agregale queso x 2€): </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredSalchipapa && (
//             <ProductList
//               products={filteredSalchipapa}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Hamburguesas: </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredHamburguesa && (
//             <ProductList
//               products={filteredHamburguesa}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Choripan: </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredChoripan && (
//             <ProductList
//               products={filteredChoripan}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Perrito: </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredPerrito && (
//             <ProductList
//               products={filteredPerrito}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Extra: </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredExtra && (
//             <ProductList
//               products={filteredExtra}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Postres: </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredPostre && (
//             <ProductList
//               products={filteredPostre}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Salsa Especiales (P : 2 /M:3.5/G:5): </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredSalsa && (
//             <ProductList
//               products={filteredSalsa}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Bebidas Especiales (x500 x 1-litro): </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredBebida && (
//             <ProductList
//               products={filteredBebida}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <div className="container pt-2">
//         <h3> Batidos (con leche 1€ extra, con donut 1€ extra): </h3>
//         <hr></hr>

//         <div className="d-flex flex-wrap justify-content-evenly">
//           {filteredBatido && (
//             <ProductList
//               products={filteredBatido}
//               auth={auth}
//               history={history}
//               profile={profile}
//             />
//           )}
//         </div>
//       </div>

//       <Notification />
//     </>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     products: state.firestore.ordered.products,
//     profile: state.firebase.profile,
//     auth: state.firebase.auth,
//     filtered: state.product.filtered,
//   };
// };

// export default compose(
//   connect(mapStateToProps),
//   firestoreConnect(() => [{ collection: "products" }])
// )(Menu);

import React, { useState, useEffect } from "react";
import Skeleton from "@yisheng90/react-loading";

import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import Notification from "../../notification/Notification";
import ProductList from "../../products/ProductList";

const Menu = ({ products, profile, auth, history, filtered }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredCategory, setfilteredCategory] = useState("");

  useEffect(() => {
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "entrante")
    );
  }, [filtered]);

  useEffect(() => {
    setfilteredCategory(
      filtered && filtered.filter((item) => item.category === "postre")
    );
  }, [filtered]);

  const onChangeEntrante = () => {
    console.log("entante", filteredCategory);
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "entrante")
    );
  };

  const onChangePostre = () => {
    console.log("postre", filteredCategory);
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "postre")
    );
  };

  const onChangeSalsa = () => {
    console.log("salsa", filteredCategory);
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "salsa")
    );
  };

  const onChangeBebidas = () => {
    console.log("bebida", filteredCategory);
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "bebida")
    );
  };

  const onChangeBatidos = () => {
    console.log("batido", filteredCategory);
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "batido")
    );
  };

  const onChangeSalchipapa = () => {
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "salchipapa")
    );
  };

  const onChangeHamburguesa = () => {
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "hamburguesa")
    );
  };

  const onChangeChoripan = () => {
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "choripan")
    );
  };

  const onChangePerrito = () => {
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "perrito")
    );
  };

  const onChangeExtra = () => {
    setFilteredProducts(
      filtered && filtered.filter((item) => item.category === "extra")
    );
  };

  return (
    <>
      <div className="container pt-2">
        <div>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangeEntrante("entrante")}
          >
            Entrantes
          </button>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangeSalchipapa("salchipapa")}
          >
            Salchipapas
          </button>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangeHamburguesa("hamburguesa")}
          >
            Hamburguesas
          </button>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangeChoripan("choripan")}
          >
            Choripan
          </button>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangePerrito("perrito")}
          >
            Perrito
          </button>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangeExtra("extra")}
          >
            Extras
          </button>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangePostre("postre")}
          >
            Postres
          </button>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangeSalsa("salsa")}
          >
            Salsas de la casa
          </button>

          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangeBatidos("batido")}
          >
            Batidos
          </button>
          <button
            className="mx-2 btn btn-danger btn-like"
            data-toggle="tooltip"
            data-placement="top"
            onClick={() => onChangeBebidas("bebida")}
          >
            Bebidas de la casa
          </button>
        </div>
        <br></br>

        <div className="d-flex flex-wrap justify-content-evenly">
          {products ? (
            <ProductList
              products={filteredProducts}
              auth={auth}
              history={history}
              profile={profile}
            />
          ) : (
            <Skeleton
              className="mx-2 my-3 skeleton"
              style={{ height: "298px", width: "15rem", borderRadius: "20px" }}
              color="rgba(255,255,255,.3)"
              rows={4}
              translucent
            />
          )}
        </div>
      </div>
      <Notification />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.firestore.ordered.products,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    filtered: state.product.filtered,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => [{ collection: "products" }])
)(Menu);
