import React from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../../firebase/actions/authActions";

const SignedInNavbar = ({ signOut, profile }) => {
  return (
    <React.Fragment>
      <div className="d-flex align-items-end justify-content-between">
        <Link to="/" onClick={signOut} className="p-0 nav-link text-center">
          <button className={`btn btn-light cart`}>
            {" "}
            <i className="fas fa-lock"></i> Cerrar
          </button>
        </Link>

        <li className="nav-item">
          <Link
            to="/perfil"
            className="d-flex justify-content-center align-items-center nav-profile-btn"
          >
            <div className="nav-profile-initials">{profile.initials}</div>
          </Link>
        </li>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(SignedInNavbar);
