import React, { useState, useEffect } from "react";
import Aos from "aos";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createProduct } from "../../firebase/actions/productActions";
// import { storage } from "../../firebase/config/fbConfig";

import { Grid, Box } from "@material-ui/core";

import ImagesDropzone from "../../imagesDropzone";
import ImageElement from "../../imageElement";

const spinner = <div className="mx-3 spinner-border" role="status"></div>;
const CreateProduct = (props) => {
  let history = useHistory();
  // const dispatch = useDispatch();

  const [imageList, setImageList] = useState([]);
  const [imageReview, setImageReview] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [id, setId] = useState(product.id);
  const [name, setName] = useState("");
  const [order, setOrder] = useState();
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [ingredientes, setIngredientes] = useState("");
  const [ribbonType, setRibbonType] = useState("");

  const changeImageField = (index, parameter, value) => {
    const newArray = [...imageList];
    newArray[index][parameter] = value;
    setImageList(newArray);
  };

  const handleChangeOrderUp = (index) => {
    // If first, ignore
    if (index !== 0) {
      const newArray = [...imageList];
      const intermediate = newArray[index - 1];
      newArray[index - 1] = newArray[index];
      newArray[index] = intermediate;
      setImageList(newArray);
    }
  };

  const handleChangeOrderDown = (index) => {
    // If last, ignore
    if (index < imageList.length - 1) {
      const newArray = [...imageList];
      const intermediate = newArray[index + 1];
      newArray[index + 1] = newArray[index];
      newArray[index] = intermediate;
      setImageList(newArray);
    }
  };

  const handleDeleteImage = (index) => {
    console.log("index", index);
    imageList[index].storageRef
      .delete()
      .then(() => {
        const newArray = [...imageList];
        newArray.splice(index, 1);
        setImageList(newArray);
      })
      .catch((error) => {
        console.log("Error deleting file:", error);
      });
  };

  useEffect(() => {
    Aos.init({ duration: 700 });
    imageList.forEach((image, index) => {
      if (image.status === "FINISH" || image.status === "UPLOADING") return;
      changeImageField(index, "status", "UPLOADING");
      const uploadTask = image.storageRef.put(image.file);
      uploadTask.on(
        "state_changed",
        null,
        function error(err) {
          console.log("Error Image Upload:", err);
        },
        async function complete() {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          changeImageField(index, "downloadURL", downloadURL);
          changeImageField(index, "status", "FINISH");
          const lUrls = imageList.map((item, index) => {
            return item;
          });
          const urls = lUrls.map((item, index) => {
            return item.downloadURL;
          });
          setImageReview(
            imageReview && imageReview.length > 0
              ? imageReview.concat(urls)
              : urls
          );
        }
      );
    });
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // let str = ingredientes;
    // let arr = str.split(",");
    console.log("category", category);
    props.createProduct({
      order: parseInt(order, 10),
      name: name,
      category: category,
      description: description,
      price: price && parseFloat(price),
      image: imageReview,
      ribbonType: ribbonType,
      ingredientes: ingredientes,
    });
    history.push("/menu");
  };

  return (
    <div className="container" data-aos="fade-up">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-lg-9 col-md-10 col-sm-11">
          <h1>Añadir un nuevo plato</h1>
          <br />
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="order" className="form-label">
                Orden
              </label>
              <input
                type="text"
                className="form-control"
                id="order"
                onChange={(e) => setOrder(e.target.value)}
                required
              ></input>
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                onChange={(e) => setName(e.target.value)}
                required
              ></input>
            </div>
            <div className="mb-3">
              <label htmlFor="category" className="form-label">
                Categoria ( entrante | salchipapa | hamburguesa | choripan |
                perrito | extra | postre )
              </label>
              <select
                name="select"
                className="form-control"
                style={{ border: "1px solid" }}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="entrante">entrante</option>
                <option value="salchipapa">salchipapa</option>
                <option value="hamburguesa">hamburguesa</option>
                <option value="choripan">choripan</option>
                <option value="perrito">perrito</option>
                <option value="extra">extra</option>
                <option value="salsa">salsa</option>
                <option value="postre">postre</option>
                <option value="bebida">bebida</option>
                <option value="batido">batido</option>
              </select>
              {/* 
              <input
                type="text"
                className="form-control"
                id="category"
                onChange={(e) => setCategory(e.target.value)}
                required
              ></input> */}
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Descripción
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                onChange={(e) => setDescription(e.target.value)}
                required
              ></input>
            </div>
            <div className="mb-3">
              <label htmlFor="price" className="form-label">
                Precio
              </label>
              <input
                type="text"
                className="form-control"
                id="price"
                onChange={(e) => setPrice(e.target.value)}
                required
              ></input>
            </div>
            <div className="mb-3">
              <label htmlFor="ribbonType" className="form-label">
                Etiqueta ( hot | sale | new )
              </label>
              <input
                type="text"
                className="form-control"
                id="ribbonType"
                onChange={(e) => setRibbonType(e.target.value)}
                // required
              ></input>
            </div>
            <div className="mb-3">
              <label htmlFor="ingredientes" className="form-label">
                Ingedientes
              </label>
              <input
                type="text"
                className="form-control"
                id="ingredientes"
                onChange={(e) => setIngredientes(e.target.value)}
                // required
              ></input>
            </div>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Imagen
              </label>

              <Box border={1} margin={4} padding={3}>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  xs={12}
                  spacing={1}
                >
                  <Grid item container xs={12} justify="center">
                    <ImagesDropzone setImageList={setImageList} />
                  </Grid>
                </Grid>
              </Box>
              {imageList &&
                imageList.length > 0 &&
                imageList.map((image, index) => {
                  return (
                    <Grid item key={image.file.size + index}>
                      <ImageElement
                        image={image}
                        index={index}
                        isFirstElement={index === 0}
                        isLastElement={index === imageList.length - 1}
                        handleChangeOrderUp={handleChangeOrderUp}
                        handleChangeOrderDown={handleChangeOrderDown}
                        handleDeleteImage={handleDeleteImage}
                        changeImageField={changeImageField}
                      />
                    </Grid>
                  );
                })}
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              //onClick={renderSpinner}
              disabled={loading}
            >
              Crear
            </button>
            {loading ? spinner : null}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (product) => dispatch(createProduct(product)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);
