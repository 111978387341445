export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_ERROR";
export const REMOVE_PRODUCT_SUCCESS = "REMOVE_PRODUCT_SUCCESS";
export const REMOVE_PRODUCT_ERROR = "REMOVE_PRODUCT_ERROR";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_FAVORITES_SUCCESS = "ADD_TO_FAVORITES_SUCCESS";
export const ADD_TO_FAVORITES_ERROR = "ADD_TO_FAVORITES_ERROR";
export const REMOVE_FROM_FAVORITES_SUCCESS = "REMOVE_FROM_FAVORITES_SUCCESS";
export const REMOVE_FROM_FAVORITES_ERROR = "REMOVE_FROM_FAVORITES_ERROR";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const ADJUST_QUANTITY = "ADJUST_QUANTITY";
export const LOAD_CURRENT_ITEM = "LOAD_CURRENT_ITEM";
export const GET_TOTAL = "GET_TOTAL";
export const CLEAR_CART = "CLEAR_CART";
export const SET_COUNT = "SET_COUNT";
export const SET_COUNT_FAV = "SET_COUNT_FAV";
export const SET_CHECKOUT_DATA = "SET_CHECKOUT_DATA";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
