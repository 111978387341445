import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyDNJ_UZ96p5fFcz38RGsufAzh92LvwE7EM",
    authDomain: "tatara-bueno.firebaseapp.com",
    databaseURL:
      "https://tatara-bueno-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "tatara-bueno",
    storageBucket: "tatara-bueno.appspot.com",
    messagingSenderId: "265927730060",
    appId: "1:265927730060:web:603de42417e70d55e46ca9",
    measurementId: "G-YJ94WKHGLC",
  });
}

const storage = firebase.storage();
// export { firebase, storage };

export { storage, firebase as default };
