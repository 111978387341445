import React, { useEffect, useState } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  removeProduct,
  // editProduct,
} from "../../firebase/actions/productActions";
import spinner from "../../../assets/svg/spinner.svg";

const RemoveProduct = ({ products, removeProduct }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Aos.init({ duration: 700 });
    setLoading(false);
  }, []);

  const entrante = products.filter((item) => item.category === "entrante");
  const postres = products.filter((item) => item.category === "postre");
  const salchipapas = products.filter((item) => item.category === "salchipapa");
  const hamburguesas = products.filter(
    (item) => item.category === "hamburguesa"
  );
  const extras = products.filter((item) => item.category === "extra");
  const choripan = products.filter((item) => item.category === "choripan");

  const perrito = products.filter((item) => item.category === "perrito");

  const salsa = products.filter((item) => item.category === "salsa");

  const bebidas = products.filter((item) => item.category === "bebida");

  const batidos = products.filter((item) => item.category === "batido");

  return (
    <div className="container" data-aos="fade-up">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-lg-9 col-md-10 col-sm-11">
          <h1>Editar/Borrar Menu:</h1>
          <h2>Entrantes:</h2>

          {/* Entrantes */}
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              entrante.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* Salchipapas */}
          <br></br>
          <br></br>
          <h2>Salchipapas</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              salchipapas.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* Hamburguesas */}
          <br></br>
          <br></br>
          <h2>Hamburguesas</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              hamburguesas.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* CHORIPAN */}
          <br></br>
          <br></br>
          <h2>Choripan</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              choripan.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* PERRITOS */}
          <br></br>
          <br></br>
          <h2>Perrito</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              perrito.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* Extras */}
          <br></br>
          <br></br>
          <h2>Extras</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              extras.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* Postres */}
          <br></br>
          <br></br>
          <h2>Postres</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              postres.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* salsas */}
          <br></br>
          <br></br>
          <h2>Salsas especiales:</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              salsa.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* bebidas */}
          <br></br>
          <br></br>
          <h2>Bebidas especiales:</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              bebidas.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>

          {/* batidos */}
          <br></br>
          <br></br>
          <h2>Batidos:</h2>
          <hr></hr>
          <ul
            className="list-group my-3"
            style={{ border: "2px solid #c0c0c0" }}
          >
            {products && products.length ? (
              batidos.map((product, index) => {
                return (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{
                      background: "rgba(29,29,29,.5)",
                      borderBottom: "2px solid #c0c0c0",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.order}
                      </label>
                      <Link to={`/producto/${product.id}`} key={product.id}>
                        <img
                          loading="lazy"
                          // onLoad={this.handleLoad}
                          src={loading ? spinner : product.image}
                          alt="productImage"
                          width="60"
                          height="60"
                        />
                      </Link>
                      <label className="mx-2" style={{ textAlign: "left" }}>
                        {product.name}
                      </label>
                      <label className="mx-2">{product.category}</label>
                      {/* <label>{product.name}</label> */}
                      <div>
                        {/* <label className="mx-2">{product.name}</label> */}
                        {/* <label className="mx-2">{`${
                          product.price && product.price.toFixed(2)
                        }€`}</label> */}
                        <Link
                          to={`/editarar/${product.id}`}
                          state={{ ...product }}
                        >
                          <button
                            className="btn btn-danger"
                            // onClick={() => editProduct(product)}
                          >
                            Editar
                          </button>
                        </Link>
                        <span style={{ width: "2px" }}> </span>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProduct(product.id)}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>There are currently no products in the menu.</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.firestore.ordered.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeProduct: (productId) => dispatch(removeProduct(productId)),
    // editProduct: (productId) => dispatch(editProduct(productId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "products" }])
)(RemoveProduct);
