import React, { Component } from "react";
import Aos from "aos";
// import Logo from "../../../../assets/images/logo.png";
// import MenuPage1 from "../../../../assets/images/pp-menu-1.jpg";
// import MenuPage2 from "../../../../assets/images/pp-menu-2.jpg";

export default class Contact extends Component {
  state = {
    isMobile: false,
    windowWidth: 0,
  };

  componentDidMount() {
    Aos.init({ duration: 700 });
    window.addEventListener(
      "resize",
      () => {
        this.setState({ isMobile: window.innerWidth < 992 });
        this.setState({ windowWidth: window.innerWidth });
      },
      false
    );
  }

  componentDidUpdate() {
    window.addEventListener(
      "resize",
      () => {
        this.setState({ isMobile: window.innerWidth < 992 });
      },
      false
    );
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="about">
            <div
              data-aos="fade-right"
              className={`d-flex${
                this.state.isMobile ? "" : " floated-iframe"
              }`}
            >
              {/* <LocationMap latitude="40.415260" longitude="-3.684500" /> */}
            </div>
            <div className="overview-contact">
              <h2 className="pb-1" data-aos="fade-left">
                Contacto
              </h2>
              {/* <img
                className="py-1 floated-logo"
                data-aos="fade-left"
                src={Logo}
                alt=""
              /> */}

              <div className="justify-text" data-aos="fade-left">
                <ul>
                  <p>
                    <i className="fas fa-home"></i>{" "}
                    <span style={{ margin: "10px" }}></span>Fast Food Latino
                    TataraBueno
                  </p>

                  <p>
                    <i class="fas fa-envelope"></i>
                    <span style={{ margin: "12px" }}></span>
                    TataraBueno@gmail.com
                  </p>

                  <p>
                    <i class="fas fa-phone"></i>{" "}
                    <span style={{ margin: "10px" }}></span>+34 629 291 727
                  </p>

                  <p>
                    <i class="fas fa-map-signs"></i>{" "}
                    <span style={{ margin: "11px" }}></span>Calle los madroños
                    2, Collado Villalba Madrid
                  </p>
                </ul>
              </div>
              
              {/* <hr /> */}
              <br></br>
              <br></br>
              <h2 data-aos="fade-right">Como llegar</h2>
              <br></br>
              <div className="justify-text" data-aos="fade-right">
                <div className="contact-map mb-10">
                  <div className="google-map">
                    <iframe
                      title="google-map"
                      src="https://www.google.com/maps/embed/v1/place?q=Calle+los+madroños+2,+Collado+++++++++++++++++++++++villalba-madrid&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                      //src="https://www.google.com/maps/embed/v1/search?key=321&q=37.4218,-122.0840+(My%20Business%20Name)&zoom=18&maptype=satellite"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
