import React from "react";

const Announcements = () => {
  return (
    <>
      {/* <h1 className="py-2" style={{ fontFamily: "'Pacifico', cursive" }}>
        Bienvenido a TataraBueno
      </h1> */}
      <p>
        {/* <b>LÉEME:</b> */}
        <br />
        Disfruta de la mejor comida latina en el restaurante Tatara. Ofrecemos
        una amplia variedad de platos frescos y de alta calidad, preparados con
        los ingredientes más frescos. ¡Reserva tu mesa hoy mismo!
      </p>
      <ol style={{ fontWeight: "300" }}>
        <li>
          Experimenta la auténtica cocina latina en Tatara. Desde sushi hasta
          ramen, nuestro menú ofrece una amplia variedad de opciones para
          satisfacer todos los gustos. <b>¡Ven a visitarnos hoy!</b>
        </li>
        <li>
          Tatara es el lugar perfecto para una cena en pareja o una comida con
          amigos. Ofrecemos un ambiente acogedor y platos de alta calidad para
          una experiencia culinaria inolvidable. <b>¡Reserva ahora!</b>
        </li>
        <li>
          Si buscas un restaurante japonés con un toque único, Tatara es la
          opción perfecta. Ofrecemos platos tradicionales con un toque moderno
          para una experiencia culinaria única. <b>¡No te lo pierdas!</b> 
        </li>
        <li>
          Vive una aventura culinaria en Tatara. Nuestro equipo de expertos
          chefs prepara platos frescos y deliciosos utilizando los mejores
          ingredientes latino.  <b>¡Reserva tu mesa hoy!</b>
        </li>
      </ol>
      {/* <p>
        <b>IMPORTANT:</b>
        <br />
        When directed to the checkout page, enter card information by using the
        card number{" "}
        <code
          style={{
            background: "#1d1d1d",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          4242
        </code>{" "}
        repeatedly, card expiry date must be in the future, and any number for
        the CVC is acceptable. Or click{" "}
        <a
          href="https://stripe.com/docs/testing"
          style={{ textDecoration: "underline" }}
        >
          here
        </a>{" "}
        for more information on basic test card numbers.
        <br />
        <b>
          * NOTE THAT ALL PERSONAL INFORMATION WILL BE KEPT FOR TESTING PURPOSES
          ONLY.
          <br />* Making payments do not work on mobile platforms.
        </b>{" "}
      </p> */}
    </>
  );
};

export default Announcements;
