import React from "react";

import { Link } from "react-router-dom";

const SignedOutSidebar = () => {
  return (
    <React.Fragment>
      <li className="nav-item">
        <Link to="/login" className="p-2 nav-link text-center">Login</Link>
      </li>
      <li className="nav-item">
        <Link to="/registrar" className="p-2 nav-link text-center">Registrar</Link>
      </li>
    </React.Fragment>
  );
}

export default SignedOutSidebar;