import React from "react";
import { Link } from "react-router-dom";
import { Container } from "../FooterPage/styleFooter";
// import {
//   BsInstagram,
//   BsFacebook,
//   BsTwitter,
//   BsTelephone,
// } from "react-icons/bs";
// import { MdMailOutline } from "react-icons/md";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import Logo from "../../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  // faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <ThemeProvider theme={theme}>
      <footer>
        <Container>
          <div className="footer-top">
            <div className="flex-col coluna1">
              <p className="descricao">
                {/* Llevando lo mejor de la comida a tu plato */}
                <h4 className="logo-name">
                  Sígue<span>nos</span>
                </h4>
              </p>

              <ul className="social">
                <li>
                  <a
                    // style={{border: "1px solid #fff"}}
                    href="http://www.instagram.com/tatarabueno"
                    // className="instagram social"
                  >
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                  </a>
                </li>
                <li>
                  <a
                    //  style={{border: "1px solid #fff"}}
                    href="https://www.facebook.com/tatarabueno/"
                    // className="facebook social"
                  >
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                  </a>
                </li>
                <li>
                  <a
                    //  style={{border: "1px solid #fff"}}
                    href="https://www.youtube.com/c/tatarabueno"
                    // className="youtube social"
                  >
                    <FontAwesomeIcon icon={faYoutube} size="2x" />
                  </a>
                </li>
              </ul>
              <h4 className="logo-name">
                <Link className="navbar-brand" to="/">
                  <img
                    src={Logo}
                    // style={{ height: "calc(3em + 1vw)" }}
                    alt="logo"
                    width={100}
                    height={100}
                  />
                </Link>
              </h4>
            </div>
            <div className="flex-col coluna2">
              <h4 className="titulo">Contacto</h4>

              <p className="descricao">
                <i class="fas fa-phone"></i>{" "}
                <span style={{ margin: "5px" }}></span> +34 629 291 727
              </p>

              <p className="descricao">
                <i class="fas fa-envelope"></i>{" "}
                <span style={{ margin: "5px" }}></span> TataraBueno@gmail.com
              </p>
              <p className="descricao">
                {" "}
                <i class="fas fa-map-signs"></i>{" "}
                <span style={{ margin: "5px" }}></span> Calle los madroños 2,
                Collado
              </p>
              <p className="descricao">Villalba Madrid</p>

              <p className="descricao">
                {/* <BsTelephone /> +55 (38)4002-8922 */}
              </p>
            </div>

            <div className="flex-col coluna3">
              <h4 className="titulo">Horario de atención</h4>
              <ul className="timings flex-col">
                <li>
                  <p className="days">Lunes</p>
                  <div className="time">10:00 - 23:00</div>
                </li>
                <li>
                  <p className="days">Martes</p>
                  <div className="time">10:00 - 23:00</div>
                </li>
                <li>
                  <p className="days">Miercoles</p>
                  <div className="time">10:00 - 23:00</div>
                </li>
                <li>
                  <p className="days">Jueves</p>
                  <div className="time">10:00 - 23:00</div>
                </li>
                <li>
                  <p className="days">Viernes</p>
                  <div className="time">10:00 - 23:00</div>
                </li>
                <li>
                  <p className="days">Sabado</p>
                  <div className="time">10:00 - 23:00</div>
                </li>
                <li>
                  <p className="days">Domingo</p>
                  <div className="time">10:00 - 23:00</div>
                </li>
              </ul>
            </div>
            <div className="flex-col coluna4">
              <h4 className="titulo">Más Informaciones</h4>
              <a alt="home" href="/">
                Home
              </a>
              <a alt="login" href="/login">
                Login
              </a>
              <a alt="registrar" href="/registrar">
                Registrar
              </a>
              <a alt="menu" href="/menu">
                Menu
              </a>
              <a alt="sobreNosotros" href="/sobreNosotros">
                Sobre Nosotros
              </a>
              <a alt="contacto" href="/contacto">
                Contacto
              </a>
            </div>
          </div>
          <hr></hr>
          <div className="footer-bottom flex-row">
            <span>Copyright &copy; 2023 | TataraBueno</span>
          </div>
          <br></br>
        </Container>
      </footer>
    </ThemeProvider>
  );
};

export default Footer;
